.menu__items {
  display: flex;
  align-items: center;
}

@media (max-width: 424px) {
  .menu__items {
    display: block;
    text-align: center;
  }
}
