.popup__title {
  text-align: center;
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: normal;
  color: #000;
}

@media (max-width: 425px) {
  .popup__title {
    font-size: 1.125rem;
  }
}
