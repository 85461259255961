.profile__info {
  box-sizing: border-box;
  display: grid;
  column-gap: 18px;
  row-gap: 8px;
  grid-template-columns: 294px 24px;
  align-items: center;
}

@media (max-width: 650px) {
  .profile__info {
    grid-template-columns: 1.8fr 0.1fr;
  }
}

@media (max-width: 375px) {
  .profile__info {
    margin-top: 26px;
    align-items: start;
    column-gap: 9px;
  }
}
