.header__logo {
  max-width: 142px;
  min-height: 33px;
  vertical-align: bottom;
}

@media (max-width: 425px) {
  .header__logo {
    max-width: 104px;
    min-height: 24px;
  }
}
