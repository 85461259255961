.profile__avatar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin: 0 auto;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
