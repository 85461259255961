.form__input {
  font-size: 0.875rem;
  line-height: normal;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-bottom: 8px;
  outline: none;
}

.form__input:nth-last-of-type(2) {
  margin: 0;
}
