.popup__close {
  border: 0;
  position: absolute;
  top: -40px;
  right: -40px;
  height: 32px;
  width: 32px;
  background: 0 0 / 32px 32px url(../../../images/form_close.svg) no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.popup__close:hover {
  opacity: 0.6;
}

@media (max-width: 768px) {
  .popup__close {
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    top: -36px;
    right: 0;
  }
}
