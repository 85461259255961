.profile__name {
  font-size: 2.625rem;
  line-height: 3rem;
  font-weight: 500;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Landscape phones and portrait tablets */
@media (max-width: 650px) {
  .profile__name {
    font-size: max(1.688rem, min(5vw, 2.625rem));
  }
}

@media (max-width: 375px) {
  .profile__name {
    font-size: 1.688rem;
    line-height: normal;
    text-align: center;
  }
}
