.popup__container {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 34px 36px 37px;
  max-width: 430px;
  background-color: white;
}

@media (max-width: 768px) {
  .popup__container {
    max-width: 282px;
  }
}
@media (max-width: 425px) {
  .popup__container {
    padding: 25px 22px 25px;
  }
}
