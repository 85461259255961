.card__like {
  border: 0;
  outline: 0;
  min-height: 19px;
  width: 22px;
  background: url(../../../images/heart.svg) no-repeat;
  background-size: 22px 19px;
  cursor: pointer;
  flex-basis: 100%;
  transition: opacity 0.5s ease;
}

.card__like:hover {
  opacity: 0.5;
}
