@font-face {
  font-family: "Inter";
  src: url(./Inter/Inter-Regular.woff) format("woff"),
    url(./Inter/Inter-Regular.woff2) format("woff2");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url(./Inter/Inter-Medium.woff) format("woff"),
    url(./Inter/Inter-Medium.woff2) format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url(./Inter/Inter-Black.woff) format("woff"),
    url(./Inter/Inter-Black.woff2) format("woff2");
  font-weight: 900;
  font-display: swap;
}
