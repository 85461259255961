.card__trash {
  position: absolute;
  right: 20px;
  top: 20px;
  border: 0;
  outline: 0;
  height: 19px;
  width: 18px;
  background: url(../../../images/trash.svg) no-repeat;
  cursor: pointer;
  visibility: hidden;
  transition: opacity 0.5s ease;
}

.card__trash:hover {
  opacity: 0.6;
}
