.elements__grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 17px;
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .elements__grids {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 425px) {
  .elements__grids {
    grid-template-columns: repeat(1, 1fr);
  }
}
