.menu__link {
  color: #fff;
  text-decoration: none;
  font-size: 1.125rem;
  line-height: 1.375rem;
  transition: opacity 0.5s ease;
}

.menu__link:hover {
  opacity: 0.6;
}
