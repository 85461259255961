.form__input-btn_disabled {
  color: #000;
  border: 1px solid #000000;
  cursor: default;
  background-color: #e5e5e5;
  opacity: 0.2;
}
.form__input-btn_disabled:hover {
  opacity: 0.2;
}
