.card__title {
  font-size: 1.5rem;
  line-height: normal;
  font-weight: 900;
  margin: 0;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-basis: 190px;
}
