.profile {
  display: grid;
  column-gap: 30px;
  align-items: center;
  grid-template-columns: min-content 1fr minmax(auto, 150px);
  max-width: 880px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .profile {
    max-width: calc(100% - 19px * 2);
  }
}

@media (max-width: 650px) {
  .profile {
    grid-template-columns: min-content 1fr minmax(auto, 15%);
    grid-auto-rows: 1fr;
  }
}

@media (max-width: 425px) {
  .profile {
    grid-template-columns: min-content 1fr;
    grid-auto-columns: 1fr;
  }
}

@media (max-width: 375px) {
  .profile {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    justify-items: center;
  }
}
